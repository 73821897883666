.cnt{
    list-style-type: none;

}
.cnt li{
    width: auto;
    text-align: left;
}
.cnt li>div{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.cnt li svg{
    margin: 0 5px 0 0;
}