.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.left{
    width: auto;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}
.form{
    width: 90%;
    max-width: 360px;
}
.logoCnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form>img, .logoCnt>img{
    width: 100px;
}
.title{
    font-size: 26px;
}
.subtitle{
    font-size: 10px;
    color: #3f4043;
}
.right{
    width: 100%;
    height: auto;
    display: none;
}


@media screen and (min-width:800px){
    .container{
        flex-direction: row;
    }
    .left{
        flex: 1;
        max-width: 550px;
    }
    .right{
        display: block;
        flex: 2;
        width: unset;
        height: 100%;
    }
}