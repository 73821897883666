.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.left{
    width: auto;
    height: auto;
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    background-color: #F8FAFB;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.leftInner{
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 31px;
}
.leftInner h1{
    font-weight: 500;
    display: flex;
    align-items: center;
}
.mEnabled{
    color: #24336D;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 10px;
}
.mDisabled{
    color: #242424;
    font-size: 13px;
    font-weight: 500;
    margin: 0 5px;
    display: flex;
    align-items: center;
}
.right{
    flex: 1;
    margin: 50px 0 0 0;
}
.rightTopCnt{
    width: auto;
    position: relative;
}
.rightTopUpper{
    width: auto;
    display: flex;
    justify-content: flex-end;
    margin: 0 15px 15px 0;
}
.rightTopUpper .select{
    right: 0;
    padding: 5px 25px;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 0 5px #F5F5F5;
    border: none;
}

.select{
    background-color: white !important;
    width: unset !important;
    flex: unset;
    border-radius: 50px !important;
    padding: 5px 15px !important;
    margin: 0 !important;
}

.rightTop{
    display: flex;
    justify-content: center;
    align-items: center;
}
.rtBtn{
    border-radius: 15px;
    height: 30px;
    width: auto;
    margin: 0 5px;
    border: 2px groove #24336D;
    color: #24336D;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    box-sizing: border-box;
    padding: 0 12px !important;
}
.rtBtn svg{
    font-size: 18px !important;
    margin: 0 2px 0 0;
}


.innerRight{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.resHolder{
    margin: 50px auto 0 auto;
    width: 90%;
    padding: 0 0 80px 0;
}
.groupcntwrapper{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    margin: 0 25px;
}
.groupcnt{
    background-color: white;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    width: auto;
    height: fit-content;
}
.gcHeader{
    width: auto;
    background-color: #F0F0F0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 25px;
}
.gcContent{
    height: auto;
    width: auto;
}

.inpTile{
    width: auto;
    height: auto;
    margin: 10px 0;
    border-spacing: 5px 0;
    padding: 0 10px;
}
.inpTile tr th{
    font-size: 14px;
}
.inpTile tr th:nth-child(1){
    background-color: transparent;
}
.inpTile tr td{
    font-size: 14px;
    /* background-color: #ffff8b; */
}
.inpTile tr td:nth-child(1){
    background-color: transparent;
}
.scenarioSelector{
    cursor: pointer;
    padding: 5px 0;
}
.resultInnerLeft{
    width: 220px;
    padding: 0 10px 0 0;
    /* background-color: #ffff8b;
    height: 47px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 25px; */
}
.resultBox{
    background-color: #ffff8b;
    min-width: 120px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin: 5px 0 !important;
    padding: 0 !important;
    width: auto;
}
.deltaTd{
    background-color: #9C27B0;
    color: white;
}
.deltaTd *{
    color: white;
}
.select{
    background-color: white !important;
    width: unset !important;
    flex: unset;
    border-radius: 50px !important;
    padding: 5px 15px !important;
    margin: 0 0 0 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.outputDetailsCnt{
    width: 95%;
    margin: 15px auto 0 auto;
    padding: 15px 0;
    display: flex;
}
.outputDetailsHeader{
color: #6d597a;
}
.outputDetailsContainer{
    width: auto;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    color: #d8315b;
    font-size: 14px;
}
.seperator{
    transform: rotate(90deg);
    margin: 0 18px;
    font-size: 25px;
}
.outputDetailsContainer>div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid gainsboro;
    padding: 0 0 5px 0;
}
.outputDetailsContainer>div .jobD{
    margin: 0 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6d597a;
    font-size: 13px;
}
.dot{
    font-size: 8px;
    margin: 0 2px 0 0;
}

.backBtn{
    box-shadow: 0 0 10px -4px gray;
    height: 55px;
    width: 55px;
    background-color: #273b8b !important;
    margin: 0 25px 0 0 !important;
    color: white;
}

.tableCnt{
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.tableName{
    margin: 0 0 0 0;
    font-weight: normal;
    font-size: 22px;
}
.table{
    border-spacing: 5px;
}

.tableHeaderBox{
    background-color: rgb(24, 170, 24) !important;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 25px;
    white-space: nowrap;
    min-width: 110px;
}
.table tr td{
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid gainsboro;
    background: white;
    border-radius: 25px;
}

.tableLeft{
    background: #ffff8b;
    font-size: 16px;
    border: none;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 25px;

}

.table tr:nth-child(2) .tableLeft,
.table tr:nth-child(3) .tableLeft,
.table tr:nth-child(4) .tableLeft,
.table tr:nth-child(5) .tableLeft,
.table tr:nth-child(6) .tableLeft{
    background: #9C27B0;
    color: white;
}

.table tr:nth-child(2) td,
.table tr:nth-child(3) td,
.table tr:nth-child(4) td,
.table tr:nth-child(5) td,
.table tr:nth-child(6) td{
    border:none;
    background: transparent;
    height: auto;
    padding: 0;
    border-radius: 0;
}

.table tr:nth-child(6) td,.table tr:nth-child(6) td:nth-child(1){
    border-bottom: 25px solid transparent;
}
.tableInpCnt{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: white;
    border: 2px solid #9C27B0;
    height: 27px;
    /* box-sizing: border-box; */
    border-radius: 25px;
    overflow: hidden;
    width: auto;
}
.tableInpCnt svg{
    font-size: 16px;
    color: rgb(56, 56, 56);
}
.tableInp{
    width: 100%;
    height: 100%;
    font-size: 13px;
    padding: 0 8px;
    border: none;
    outline: none;
}
.cashflowValCnt{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: auto;
}
.cashflowValCnt svg{
    font-size: 16px;
    margin: 0 3px 0 0;
    color: rgb(56, 56, 56);
}




.cmpListCnt{
    width: 90vw;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.listCnt{
    padding: 15px;
    flex: 1;
    margin: 5px;
}
.mainListCnt{
    width: auto;
    min-height: 50px;
    background-color: whitesmoke;
    border-radius: 25px;
    padding: 5px 10px;
    box-shadow: 5px 5px 10px -4px gray;
}
.listTileCnt{
    width: auto;
    max-width: 200px;
}
.listTile{
    width: 100%;
    background-color: #9C27B0;
    color: white;
    padding: 7px 15px;
    margin: 7px 0;
    border-radius: 25px;
    font-size: 14px;
    box-sizing: 0 0 10px gray;
}
.cmpListTop{
    width: 100%;
}
.cmpListBot{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.highlightCnt{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0 15px 0;
}
.highlightChild{
    /* border: 2px solid #9C27B0; */
    width: 100%;
    max-width: 480px;
    border-radius: 15px;
    padding: 0 5px;
    margin: 5px 5px;
}
.highlightTile{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 7px 0;
    background-color: #ffff8b;
}
.highlightTile svg{
    font-size: 22px;
    color: rgb(56, 56, 56);
}
.highlightTleft{
    flex: 1;
    border-radius: 25px;
    background-color: #9C27B0;
    margin:  0 10px 0 0;
    padding: 3px 0 3px 8px;
    color: white;
}
.highlightTright{
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 5px 8px;
    border-radius: 25px;
}

@media screen and (min-width:800px){
  
    .highlightCnt{
        flex-direction: row;
        justify-content: center;
    }
    .highlightChild{
        width: 300px;
        max-width: auto;
    }
    .cmpListBot{
        flex-direction: row;
        align-items: flex-start;
    }
}
@media screen and (min-width:1000px){
    .outputDetailsCnt{
        width: auto;
        padding: 15px 0 15px 0;
        margin: 0 60px 0 60px;
    }
    .indexMob{
        display: none;
    }
    .container{
        flex-direction: row;
        align-items: flex-end;
    }
    .left{
        padding: 0 20px 0 60px;
    }
    .leftInner{
        max-width: unset;
    }
    .leftInner>p{
        margin: 0 10px;
    }
    .leftInner>h1{
        font-size: 40px;
    }
    .leftInner>img{
        width: 400px;
    }
    .leftInner>h4{
        font-size: 18px;
    }
    .right{
        display: flex;
        flex-direction: column;
        padding: 0 60px 0 0;
    }
    .rtBtn{
        padding: 15px 25px;
        font-size: 14px;
    }
    .rtBtn svg{
        font-size: 20px;
    }
    .rightTop{
        justify-content: flex-end;
    }
    .innerRight{
        align-items: flex-end;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
    }
    .tableCnt{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .gcContent{
        overflow: hidden;
    }
    .mDisabled{
        font-size: 16px;
    }
    .mEnabled{
        font-size: 30px;
    }
    
}

@media screen and (min-width:1200px){
    .innerRight{
        padding-left: 70px;
    }
}
@media screen and (min-width:1300px){
    
}
