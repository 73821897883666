.mainCnt{
    display: flex;
    align-items: center;
}
.mainCnt img{
    height: 100%;
}
.divider{
    height: 38px;
    width: 2px;
    background-color: gainsboro;
    border-radius: 10px;
    margin: 0 9px 0 9px;
}
.devTxt{
    font-size: 13px;
    margin: 0 2px 0 0;
}
.partnerTxt{
    font-size: 0.5em;
    color: black;
    letter-spacing: -0.8px;
}
.partnerTxt span{
    font-weight: bold;
}

@media screen and (min-width:800px){
    .mainCnt{
        margin: 0 15px;
    }
    .developedTxt{
        font-size: 16px;
    }
    .logoContainer svg{
        height: 65px;
    }
    .brandTxt{
        font-size: 24px;
    }
    .supportTxt{
        font-size: 12px;
    }
}