.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.left{
    width: auto;
    height: auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    display: none;
}
.leftInner{
    max-width: 500px;
}
.leftInner>h1{
    color: #24336D;
    font-size: 30px;
    margin-top: 31px;
    font-weight: 500;
}
.leftInner>h1 sup{
    font-size: 14px;
}
.leftInner>img{
    width: 360px;
    margin: 20px 0 0 0;
}
.leftInner>h4{
    color: #444444;
    font-weight: 500;
}
.right{
    flex: 1;
    margin: 15px 15px 45px 15px;
    display: flex;
    flex-direction: column;
}
.rightTopCnt{
    width: auto;
    position: relative;
}
.rightTopUpper{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: 0 0 0 0;
}

.clock{
    background-color: white !important;
    color: black !important;
    border: none;
    cursor: unset;
    height: 55px;
    width: 250px;
    border-radius: 100px;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.clockCircle{
    display: none;
}

.rightTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 0;
    order: 2;
    width: 250px;
}
.currencyDrop{
    right: 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 5px #F5F5F5;
    height: 40px;
    display: flex;
    align-items: center;
}
.rtBtn{
    border-radius: 50px;
    background-color: #24336D;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.innerRight{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex: 1;
}
.calcCnt{
    width: 100%;
    margin: 30px 0 5px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.calcHeader{
    border-top-left-radius: 30px;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 20px;
}
.calcHeader>div{
    display: flex;
    align-items: center;
}
.bullet{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #3777BC;
    margin: 0 10px 0 0;
}
.calcMain{
    padding: 15px 0 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    width: 100%;
    overflow: hidden;
}
.calcContent{
    height: auto;
    width: 100%;
    display: flex;
    
}
.calcDivisionCnt{
    margin-right: 25px;
    max-width: 270px;
    width: 100%;
}
.dataHeaderCnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px 0;
}
.dataHeader{
    display: block;
    font-weight: bold;
    color: #24336D;
}
.calcBtnCnt{
    display: flex;
    width: 100%;
    margin: 20px 0 5px 0;
    max-width: 400px;
}
.calcBtn{
    padding: 10px 15px;
    height: auto;
    background-color: #24336D;
    border-radius: 30px;
    color: white;
    margin: 0 3px;
    flex: 1;
}
.calcBtn:hover{
    background-color: #263d9b !important;
    color: white !important;
}
.calcBtn:disabled{
    background-color: #3777BC;
    color: white;
    opacity: .5;
}


.setScenarioCnt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 15px 0;
    background-color: #f5e9ffa3;
    border-radius: 5px;
}
.setCnt{
    margin: 0 0 0 0;
}
.setTitle{
    font-size: 20px;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
    border-bottom: 1px solid gainsboro;
}
.setScenarioLeft{
    display: flex;
    align-items: center;
    padding: 5px 5px;
}

.setScenarioLeft label{
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    flex: 1;
}
.setChkBox{
    margin: 0 10px 0 0;
}
.setScenarioRight{
    padding: 5px 5px;
    background-color: #eaddf4a3;
}
.setJobSelectCnt{
    min-width: 150px;
}

@media screen and (min-width:800px){
    .calcCnt{
        width: 90%;
    }
    .setScenarioCnt{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .setTitle{
        font-size: 22px;
    }
    .setScenarioLeft{
        width: 350px;
        padding: 15px 15px 15px 15px;
        box-sizing: border-box;
    }
    .setScenarioRight{
        padding: 15px 15px 15px 15px;
        border-radius: 10px;
    }
}
@media screen and (min-width:1000px){
    .indexMob{
        display: none;
    }
    .container{
        flex-direction: row;
        height: 100%;
        padding: 0 0 50px 0;
    }
    .left{
        display: flex;
        padding: 0 20px 0 60px;
    }
    .leftInner>h1{
        font-size: 40px;
    }
    .leftInner>img{
        width: 400px;
    }
    .leftInner>h4{
        font-size: 18px;
    }
    .right{
        display: flex;
        flex-direction: column;
        margin: 0;
    }
   
    .innerRight{
        align-items: flex-end;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .calcCnt{
        width: 100%;
        border-top-left-radius: 45px;
        background: url("../../public/images/calc_bk.png");
        background-color: white;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 50vh;
        margin: 30px 0 0 0;
    }
    .calcMain{
        padding: 15px 0 15px 0;
    }
    .calcHeader{
        height: 60px;
        padding: 15px 0 15px 50px;
        border-top-left-radius: 50px;
    }
    .bullet{
        margin-right: 20px;
    }
    .calcHeader>div p{
        font-size: 20px;
        font-weight: 500;
    }
    .calcContent{
        padding-top: 20px;
    }
    .calcBtnCnt{
        max-width: 300px;
        padding-left: 0;
       
    }

    .calcBtnCnt{
        margin: 30px 0 20px 0;
    }
    
}

@media screen and (min-width:1200px){

    .clockCircle{
        height: 140px;
        width: 140px;
        background-color: white;
        border-radius: 50%;
        display: block;
        order: 2;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 15px 0 0;
        /* box-shadow: 0 0 10px -3px lightgray; */
        border: 13px groove lightgray;
    }
    .rtBtn{
        height: 50px;
        margin: 0 10px 0 0; 
        font-size: 16px;
    }
    .currencyDrop{
        height: 50px;
        font-size: 16px;
        margin: 0;
        border-radius: 50px;
    }
    .rightTop{
        justify-content: flex-end;
        order: 1;
        width: auto;
    }
    .clock{
        order: 2;
        margin: auto 0 auto 10px;
        font-size: 16px;
        display: none;
    }
    .rightTopUpper{
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    .leftInner>img{
        width: 500px;
    }
    .innerRight,.rightTopUpper{
        padding-left: 70px;
    }
    .calcContent,.calcBtnCnt{
        padding-left: 60px;
    }
    .calcHeader{
        padding: 15px 0 15px 60px;
    }
}

@media screen and (min-width:1300px){
    .rtBtn{
        width: auto;
        height: 50px;
        padding: 0 30px;
    }
    .currencyDrop{
        width: auto;
        height: 50px;
        padding: 0 10px;
    }
    .clock{
        height: 60px;
        min-width: 270px;
        padding: 0 15px;
    }
}